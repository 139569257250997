import React, { useState, useEffect } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const AdminDashboard = () => {
  //order total
  const [totalIncome, setTotalIncome] = useState(0);

  //montly income
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  //useeffect for the order total
  useEffect(() => {
    const fetchTotalIncome = async () => {
      try {
        const response = await fetch("https://backend.spkstore.lk/order/getTotalIncome");
        const result = await response.json();
        if (result.statusCode === 200) {
          setTotalIncome(result.data.totalIncome);
        } else {
          console.error("Failed to fetch total income:", result.message);
        }
      } catch (error) {
        console.error("Error fetching total income:", error);
      }
    };

    const fetchMonthlyIncome = async () => {
      try {
        const response = await fetch("https://backend.spkstore.lk/order/getMonthlyIncome");
        const result = await response.json();
        console.log(result);
        
        if (result.statusCode === 200) {
          setMonthlyIncome(result.data.totalMonthlyIncome);
        } else {
          console.error("Failed to fetch monthly income:", result.message);
        }
      } catch (error) {
        console.error("Error fetching monthly income:", error);
      }
    };

    fetchTotalIncome();
    fetchMonthlyIncome();
  }, []);

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Sidebar starts here */}
      <div
          className={`${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed inset-0 lg:translate-x-0 lg:relative lg:w-64 w-80 bg-red-300 text-black transition-transform duration-300 pl-4`}
        >
          <div className="flex items-center justify-end p-4">
            {/*<span className="text-xl font-bold">Results</span>*/}
            <button
              className="lg:hidden font-semibold text-white"
              onClick={toggleSidebar}
            >
              <CloseOutlinedIcon></CloseOutlinedIcon>
            </button>
          </div>
        <aside style={{ width: "15rem" }} className="h-screen bg-red-300">
          <div className="p-6">
            {/* Company image */}
            <div className="flex flex-col items-center space-y-2">
              <img
                src="/images/spklogo.png"
                alt="SPK Store"
                style={{ width: "8rem", height: "8rem" }}
              />
            </div>

            <nav className="mt-10 space-y-2">
              <button
                className="flex items-center space-x-3 text-gray-700 p-3 rounded-md hover:bg-white focus:outline-none w-48"
                onClick={() => (window.location.href = "/admin")}
              >
                <img
                  src="/images/dashboardiconblack.png"
                  alt="Dashboard"
                  className="h-6 w-6"
                />
                <span className="font-semibold">Dashboard</span>
              </button>
              <button
                className="flex items-center space-x-3 text-gray-700 p-3 rounded-md hover:bg-white focus:outline-none w-48"
                onClick={() => (window.location.href = "/addproduct")}
              >
                <img
                  src="/images/Addimage.png"
                  alt="Add Product"
                  className="h-6 w-6"
                />
                <span className="font-semibold">Add Product</span>
              </button>
              <button
                className="flex items-center space-x-3 text-gray-700 p-3 rounded-md hover:bg-white focus:outline-none w-48"
                onClick={() => (window.location.href = "/adminOrder")}
              >
                <img
                  src="/images/shoppingBag.png"
                  alt="My Order"
                  className="h-6 w-6"
                />
                <span className="font-semibold">My Order</span>
              </button>
            </nav>
          </div>
        </aside>
      </div>
      {/* Sidebar ends here */}

      {/* Main content */}
      <div className="flex-1 p-4 sm:p-6 bg-gray-50 relative">
        <div className="flex gap-4 justify-between mb-2">
            <button 
             className="text-red-400 font-bold text-lg block lg:hidden mb-4"
             onClick={toggleSidebar}
             >
                ☰
            </button>
            <h2 className="text-lg sm:text-xl lg:text-3xl font-semibold">All Income</h2>
            {/* User Profile Section */}
            <div className="flex items-center space-x-2">
              <img
                src="/images/Adminlogo.png"
                alt="User"
                className="w-10 h-10 rounded-full"
              />
              <div className="text-right text-sm sm:text-base lg:text-lg">
                <p className="font-semibold text-start">Admin</p>
                <p className="text-gray-500">Welcome</p>
              </div>
            </div>
        </div>
        

        <div className="flex justify-between items-center mb-8">
          <div className="flex space-x-3 md:space-x-6 mt-12 max-sm:w-full">
            {/* All income section */}

            <div className="bg-gray-100 text-center p-2 sm:p-8 rounded-lg shadow-md w-1/2 max-md:h-20 md:w-80">
              <h3 className="text-base sm:text-xl font-semibold">All Income</h3>

              {/* order total value display */}
              <p className="text-lg sm:text-3xl mt-0 sm:mt-2">RS {totalIncome.toLocaleString()}.00</p>
            </div>

            {/* Monthly income section */}
            <div className="bg-gray-100 text-center p-2 sm:p-8 rounded-lg shadow-md w-1/2 max-md:h-20 md:w-80">
              <h3 className="text-base sm:text-xl font-semibold">Monthly Income</h3>

              {/* monthly order value display */}
              <p className="text-lg sm:text-3xl mt-0 sm:mt-2">RS {monthlyIncome.toLocaleString()}.00</p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="flex justify-end items-center max-sm:absolute max-sm:bottom-0 max-sm:right-0 sm:mt-[-8px] pr-2">
          <img src="/images/Man.png" alt="Man" className="object-bottom w-2/3 md:w-96 h-auto" />
        </div>
      </div> 
    </div>
  );
};

export default AdminDashboard;
