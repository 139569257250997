import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import axios from "axios";
import Pagination from "../Components/Pagination";
import Records from "../Components/Records";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function DronesPage() {
  const cookieget = Cookies.get("memberToken");

  let username = "";

  if (cookieget) {
    const decoded = jwtDecode(cookieget);
    if (decoded.given_name) {
      username = decoded.given_name;
    } else {
      username = decoded.username || "";
    }
  }

  // const decoded = jwtDecode(cookieget);
  // console.log(decoded);

  // const username = decoded.username;

  // pagination test data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(9);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [results, setResults] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // const [events, setEvents] = useState([]);

  // useEffect(() => {
  //   axios.get("https://backend.spkstore.lk/product/getProducts").then((Response) => {
  //     console.log(Response);
  //     // setEvents(Response.data.data);
  //     setData(Response.data.data);
  // setLoading(false);
  //   });
  // }, []);

  // const [userinfo, setUserInfo] = useState({ languages: [] });

  const handleChange = async (e) => {
    const { name, value, checked } = e.target;
    setSelectedOptions((prevState) =>
      checked
        ? [...prevState, { field: name, value }]
        : prevState.filter(
            (option) => option.field !== name || option.value !== value
          )
    );
  };

  // Fetch data whenever selectedOptions change
  useEffect(() => {
    if (selectedOptions.length > 0) {
      axios
        .post("https://backend.spkstore.lk/product/getProductsFilter", {
          checkboxes: selectedOptions,
        })
        .then((response) => {
          // setResults(response.data);
          setData(response.data.data);
          console.log(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get("https://backend.spkstore.lk/product/getProducts")
        .then((response) => {
          // Filter the products where itemType is "Drone"
          const filteredData = response.data.data.filter(
            (product) => product.itemType === "Drone"
          );
          setData(response.data.data);
          setData(filteredData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedOptions]);

  console.log(data);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord)

  let currentRecords;
  let nPages = [];

  if (Array.isArray(data) && data.length > 0) {
    currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  } else {
    currentRecords = []; // or null, depending on your preference
  }

  // Then, you can check if currentRecords is empty and display a message
  if (currentRecords.length === 0) {
    console.log("No records found.");
    // Display a message to the user in your UI
  } else {
    // Proceed with displaying the currentRecords
    nPages = Math.ceil(data.length / recordsPerPage); // Ensure at least one page if data is not empty
  }

  const FilterName = ({filtername,filterfield}) => {
    return (
      <div className="flex items-center align-middle">
        <input
          value= {filtername}
          name={filterfield}
          // defaultChecked=""
          // id="flexCheckDefault"
          // name="languages"
          type="checkbox"
          checked={selectedOptions.some(
            (option) =>
              option.field === filterfield && option.value === filtername
          )}
          onChange={handleChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
        <p className="ml-3" style={{ color: "#757575" }}>
          {filtername}
        </p>
      </div>
    );
  };
  // Calculate number of pages

  return (
    <div className="flex h-screen flex-col">
      <Navbar data={username} />

      <div className="2xl:flex overflow-hidden relative flex-col justify-center items-start min-h-[420px] max-md:pr-5">
        <img
          loading="lazy"
          src="images/Drone.png"
          className="object-cover absolute inset-0 size-full sm:aspect-[1.85]"
        />
        <img
          loading="lazy"
          src="images/Drone3.png"
          className="sm:hidden block object-cover absolute inset-0 size-full"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9960fc1df6c020e8e19f8eb538773352dab936d80fb9432ce1c852a919c8ee4?"
          className="absolute hidden sm:block bg-opacity-80 w-7/12 h-full sm:object-none sm:object-right lg:object-cover"
        />
        <p className="absolute left-4 sm:left-20 lg:left-44 mt-[340px] sm:mt-44 text-5xl md:text-6xl lg:text-7xl lx:text-8xl text-white font-bold tracking-wide">
          Drones
        </p>
      </div>
      <button
        className="md:hidden text-xl sm:text-2xl mb-4 font-semibold bg-red-400 text-white m-4 p-2"
        onClick={toggleSidebar}
      >
        Filter ☰ 
      </button>
      <div className="flex flex-row">
        <div className={`${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed inset-0 md:translate-x-0 md:relative w-64 sm:w-3/5 lg:w-2/5 xl:w-2/6 bg-white text-red-400 transition-transform duration-300 pl-4 border-r border-gray-500 overflow-y-auto`}>
          <div className="flex mt-10 mb-5 justify-between">
            <h1 className="text-2xl font-semibold">Results</h1>
            <a href="" className="hidden sm:block ml-20 sm:ml-28 lg:ml-32 text-sm text-red-400 border mr-2">
              Clear all
            </a>
            <button
              className="md:hidden text-lg pr-2"
              onClick={toggleSidebar}
            >
              <CloseOutlinedIcon></CloseOutlinedIcon>
            </button>
          </div>
          <div>
            {/* Brands labels */}
            <h3 className="text-lg font-semibold mb-4">Brands</h3>
            <FilterName filtername="DJI" filterfield="selectedBrand"></FilterName>
            <FilterName filtername="Potensic" filterfield="selectedBrand"></FilterName>
            <FilterName filtername="FIMI" filterfield="selectedBrand"></FilterName>
          </div>
         
          {/* <div className="mt-4">
         
            <h2 className="text-lg font-semibold">Availability</h2>

            <div className="flex items-center align-middle mt-4">
              <input
                value="In stock"
                name="availability"
                type="checkbox"
                checked={selectedOptions.some(
                  (option) =>
                    option.field === "availability" &&
                    option.value === "In stock"
                )}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <p className="ml-3" style={{ color: "#757575" }}>
                In Stock
              </p>
            </div>

            <div className="flex items-center align-middle">
              <input
                value="Pre Order"
                name="availability"
                type="checkbox"
                checked={selectedOptions.some(
                  (option) =>
                    option.field === "availability" &&
                    option.value === "Pre Order"
                )}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <p className="ml-3" style={{ color: "#757575" }}>
                Pre Order
              </p>
            </div>
          </div> */}

          <div className="mt-4">
            <h2 className="text-lg font-semibold md-4">Offers</h2>
            <FilterName filtername="Hot Deals" filterfield="Offers"></FilterName>
            
            {/* Hot deals check */}
          </div>

          <div className="mt-4">
            {/* Camera Label */}
            <h2 className="text-lg font-semibold mb-4">Camera</h2>
            <FilterName filtername="2.7K30FPS" filterfield="camera"></FilterName>
            <FilterName filtername="4K30FPS" filterfield="camera"></FilterName>
            <FilterName filtername="4K60FPS" filterfield="camera"></FilterName>
            <FilterName filtername="8K" filterfield="camera"></FilterName>
          </div>

          <div className="mt-4">
            {/* Range Checkbox set */}
            <h2 className="text-lg font-semibold mb-4">Range</h2>
            <FilterName filtername="3KM" filterfield="range"></FilterName>
            <FilterName filtername="6KM" filterfield="range"></FilterName>
            <FilterName filtername="9KM" filterfield="range"></FilterName>
            <FilterName filtername="10KM" filterfield="range"></FilterName>
            <FilterName filtername="20KM" filterfield="range"></FilterName>
          </div>

          <div className="mt-4 mb-32">
            {/* fly time checkbox set */}
            <h2 className="text-lg font-semibold mb-4">Fly Time</h2>
            <FilterName filtername="32min" filterfield="flyTime"></FilterName>
            <FilterName filtername="64min" filterfield="flyTime"></FilterName>
            <FilterName filtername="96min" filterfield="flyTime"></FilterName>
            <a href="" className="sm:hidden ml-44 text-sm text-red-400 border">
              Clear all
            </a>
          </div>
        </div>

        {/* pagination test with  data */}

        <div className="container m-5">
          <div className="flex flex-col align-middle">
            <div className="h-[1350px]">
              {nPages > 0 ? (
                <Records data={currentRecords} />
              ) : (
                <p className="text-lg tracking-wider font-thin italic mx-auto justify-center items-center ">No records found.</p>
              )}
            </div>
            {nPages > 0 && (
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>

        {/* pagination test done  */}
      </div>
      <Footer />
    </div>
  );
}

export default DronesPage;
