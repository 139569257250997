import React, { useRef } from "react";
import { Carousel } from "flowbite-react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import HomepageComments from "../Components/HomepageComments";
import HomePageHotDeals from "../Components/HomePageHotDeals";
import HomepagePopular from "../Components/HomepagePopular";
import CategoryBox from "../Components/CategoryBox";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useState } from "react";
// responsive enable
function Homepage() {
  //check----------------------local
  /*const [message, setMessage] = useState('');

  const fetchHelloWorld = () => {
    axios
      .get('http://localhost:8070/api/hello')
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((error) => {
        console.error('There was an error fetching the message!', error);
      });
  };*/
  //check ----------------------local

    const curRef = useRef();
    
    
    // Inside your component
const navigate = useNavigate();

    const eventScroll = (direction) => {
      if (direction === "left") {
        if (curRef) {
          curRef.current.scrollLeft -= 300;
        }
      } else {
        if (curRef) {
          curRef.current.scrollLeft += 300;
        }
      }
    };



    const handleShopNow = () => {
      navigate('/drones');
    };
  
    return (
      <div>
        <Navbar/>
        <div className="flex min-h-full flex-1 flex-col justify-center px-0 pt-0 pb-12">
          <div className="h-80 sm:h-64 xl:h-96 2xl:h-1/2 overflow-hidden bg-black">
            <Carousel slideInterval={3000}>
                          <div className="relative w-full h-full">
                            <img
                              className="w-full h-full object-cover object-[75%_50%]"
                              src="images/HomeImage.png"
                              alt="Home Image"
                            />
                            <button className="absolute sm:bottom-[88px] sm:left-[65px] md:bottom-[87px] md:left-[148px] lg:bottom-[74px] lg:left-[200px] xl:bottom-[114px] xl:left-[280px] transform -translate-x-1/2 sm:w-20 sm:h-5 md:w-20 lg:w-[100px] lg:h-7 xl:w-36 xl:h-10 bg-white bg-opacity-0 text-white border-none rounded-lg text-sm hover:bg-opacity-0 transition duration-300" onClick={handleShopNow}>
                              
                            </button>
                          </div>
                          <img
                          className="w-full h-full object-none object-[52%_60%] sm:object-fill sm:object-center"
                            src="images/EndImage.png"
                            alt="..."
                          />
                          <div className="relative w-full h-full">
                            <img
                              className="w-full h-full object-cover object-[75%_50%]"
                              src="images/HomeImage.png"
                              alt="Home Image"
                            />
                            <button className="absolute sm:bottom-[88px] sm:left-[65px] md:bottom-[87px] md:left-[148px] lg:bottom-[74px] lg:left-[200px] xl:bottom-[114px] xl:left-[280px] transform -translate-x-1/2 sm:w-20 sm:h-5 md:w-20 lg:w-[100px] lg:h-7 xl:w-36 xl:h-10 bg-white bg-opacity-0 text-white border-none rounded-lg text-sm hover:bg-opacity-0 transition duration-300" onClick={handleShopNow}>
                              
                            </button>
                          </div>
                          <img
                            className="w-full h-full object-none object-[52%_60%] sm:object-fill sm:object-center"
                            src="images/EndImage.png"
                            alt="..."
                          />
                          <div className="relative w-full h-full">
                            <img
                              className="w-full h-full object-cover object-[75%_50%]"
                              src="images/HomeImage.png"
                              alt="Home Image"
                            />
                            <button className="absolute sm:bottom-[88px] sm:left-[65px] md:bottom-[87px] md:left-[148px] lg:bottom-[74px] lg:left-[200px] xl:bottom-[114px] xl:left-[280px] transform -translate-x-1/2 sm:w-20 sm:h-5 md:w-20 lg:w-[100px] lg:h-7 xl:w-36 xl:h-10 bg-white bg-opacity-0 text-white border-none rounded-lg text-sm hover:bg-opacity-0 transition duration-300" onClick={handleShopNow}>
                              
                            </button>
                          </div>
                        </Carousel>
          </div>
          <div className="p-5 mt-3 sm:mx-auto sm:w-full sm:max-w-xl lg:max-w-4xl xl:max-w-7xl">
           
            <div className="text-center p-5 mt-1 sm:mt-2 md:mt-4 lg:mt-6 xl:mt-8">
              <h3 className="text-xl font-bold tracking-wide">Our Categories</h3>
              <p className="font-semibold text-gray-400 tracking-wide mt-1">
                Explore our main categories on the go
              </p>
            </div>
            <div className='flex flex-row gap-3 md:gap-6 xl:gap-10'>
              <CategoryBox href_name="/gimbles" image_name="images/Gimbles.png" category_name="Gimbal"></CategoryBox>
              <CategoryBox href_name="/drones" image_name="images/Drones.png" category_name="Drone & Accessories"></CategoryBox>
              <CategoryBox href_name="/smartwatches" image_name="images/Smartwatches.png" category_name="Smart Watch"></CategoryBox>
              <CategoryBox href_name="/others" image_name="images/others1.png" category_name="Other Accessories"></CategoryBox>
            </div>

            {/* fre ship sectoin */}
            <div className="2xl:flex align-middle items-center p-3 justify-between bg-gray-100 rounded-md mt-12 ">
              {/* free shipping */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                     // alt=" free shipping"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      //alt="Your Companyx"
                      src="/images/freeShipping.png"
                      className="mx-auto absolute bottom-0  mb-3 ml-4"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start ml-1 text-left">
                  <p className="mt-2">Free Shipping</p>
                  <p className="mt-2 text-gray-400 ">
                    Free shipping for All fully Payment Item
                  </p>
                </div>
              </div>
              {/* onr to one warranty */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                      alt="Your Company"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      alt="Your Companyx"
                      src="/images/OnetoOne.png"
                      className="mx-auto absolute bottom-0 ml-4 mb-1"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col text-left items-start ml-1">
                  <p className="mt-2">One to one Warranty</p>
                  <p className="mt-2 text-gray-400">
                    Within 30 days for an exchange
                  </p>
                </div>
              </div>
              {/* easy payment */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                      alt="Your Company"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      alt="Your Companyx"
                      src="/images/EasyPayment.png"
                      className="mx-auto absolute bottom-0 ml-1 mb-3 right-3"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col text-left items-start ml-1">
                  <p className="mt-2">Easy Payment</p>
                  <p className="mt-2 text-gray-400">
                    Pay with multiple Credit Cards
                  </p>
                </div>
              </div>
              {/* onlinr support */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                      alt="Your Company"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      alt="Your Companyx"
                       src="/images/Online.png"
                      className="mx-auto absolute bottom-0 ml-1 right-3 mb-2"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col text-left items-start ml-1">
                  <p className="mt-2">Online Support</p>
                  <p className="mt-2 text-gray-400">24/7 Support</p>
                </div>
              </div>
            </div>
  
            
  
            {/* hot deal section */}
            <div className="hidden sm:block">
              <h2 className="text-left mt-12 font-bold text-xl">Hot Deals</h2>
              <HomePageHotDeals />
            </div>
            {/* popular products */}
            <h2 className="text-left mt-12 font-bold text-xl mb-10">
              Popular Products
            </h2>
  
            <div className="2xl:flex flex-row align-middle items-center justify-between max-w-full ">
              <HomepagePopular />
            </div>
            {/* save amount 20% banner */}
            <div className="flex  bg-red-400 mt-32 rounded-xl">
              <div className="bg-red-400 w-4/5 rounded-l-xl hidden xl:block">
                <div className=" relative ">
                  <div className=" h-80 w-full relative overflow-hidden  ">
                    <div className="ml-24 box-content absolute bottom-12 h-80 w-80 m-auto border bg-white rounded-b-full rounded-l-full rounded-r-full"></div>
                  </div>
                  <div>
                    <img
                      alt="Your Company"
                      src="images/handpic.png"
                      className=" absolute h-96  bottom-0 right-16 "
                      // width={900}
                      // height={30}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-10 p-4 xl:p-0">
                <h2 className="font-bold text-xl md:text-3xl text-white">
                  Score Big Saving on Sport Gear
                </h2>
                <p className="mt-5 text-white">
                Explore a wide range of products across various categories, from drones and gimbals to smartwatches and more. We are excited to announce that you can enjoy discounts of up to 20% on selected items!{" "}
                </p>
                <div className="flex align-middle items-center justify-between mt-10">
                  <h1 className="text-5xl sm:text-8xl font-semibold text-left text-white">
                    20%
                  </h1>
                  <button className="border px-3 py-1 sm:px-10 sm:py-2.5 rounded-md mr-5 sm:mr-10 lg:mr-28 font-bold text-white tracking-wide outline outline-2" onClick={handleShopNow}>
                    SHOP NOW
                  </button>
                </div>
              </div>
            </div>
  
            {/* brands */}
  
            <h2 className="text-left mt-12 font-bold text-xl mb-2">
            Our Brands
          </h2>
          <div className="2xl:flex flex-col mt-10">
              <div className="w-full bg-zinc-100 min-h-[5px] max-md:max-w-full" />
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 justify-between items-center mt-7 w-full max-md:flex-wrap max-md:max-w-full">
                <img
                  alt="Your Company"
                  src="/images/Rectangle 407.png"
                  width={80}
                  className="shrink-0 self-stretch max-w-full aspect-[1.82] w-[115px] "
                />
                <img
                  alt="Your Company"
                  src="/images/Rectangle 408.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto max-w-full aspect-[3.7] w-[182px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Rectangle 410.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto aspect-[1.64] w-[94px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Potensic.png"
                  width={80}
                  className="shrink-0 self-stretch max-w-full aspect-[3.03] w-[193px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Zeblaze.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto max-w-full aspect-[2.94] w-[161px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Aochuan.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto max-w-full aspect-[4.17] w-[213px]"
                />
              </div>
              <div className="mt-7 w-full bg-zinc-100 min-h-[5px] max-md:max-w-full" />
            </div>
  
            {/* comments */}
            <HomepageComments />
          </div>
  
          {/* the big image */}
        </div>
        <div className="h-56 sm:h-64 xl:h-80 2xl:h-full max-w-full">
          <img
            alt="Your Company"
            src="images/EndImage.png"
            className="w-full max-sm:h-full"
            //   width={550}
            // height={}
          />
        </div>
        {/* <button onClick={fetchHelloWorld}>Fetch Hello World</button>
          {message && <p>{message}</p>} */}
        <Footer />
      </div>
    );
}

export default Homepage