import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const ProtectedRoutes = () => {
    //const user = true;
    const token = Cookies.get("memberToken"); 
    let username = "";
    //check if admin has logged before
      if (token) {
        const decoded = jwtDecode(token);
        if (decoded.given_name) {
          username = decoded.given_name;
        } else {
          username = decoded.username || "";
        }
      }

    return username ==="spkstoreAdmin" ? <Outlet/> : <Navigate to="/adminLogin"/>
}

export default ProtectedRoutes;