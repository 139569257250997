import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios'; 

function OTPRecovery() {
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(120); 
    const [message, setMessage] = useState('');
    const [isOtpValid, setIsOtpValid] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    // otp verify part
    useEffect(() => {
        if (timer > 0) {
          const intervalId = setInterval(() => setTimer(timer - 1), 1000);
          return () => clearInterval(intervalId); 
        }
      }, [timer]);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (timer === 0) {
          setMessage('OTP expired. Please request a new one.');
          return;
        }
    
        try {
          
          const response = await axios.post("https://backend.spkstore.lk/password/verifyotp", {
            email,
            otp 
          });
    
          
          if (response.status === 200) {
            setIsOtpValid(true);
            navigate('/passwordReset', { state: { email: email } }); // Redirect to reset password page
          } else {
            setMessage(response.data.message || 'Invalid OTP.');
          }
          console.log(response);
        } catch (error) {
          setMessage('Something went wrong. Please try again.');
          console.log(error);
        }
      };

    return(
    <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-14">
            <div className="sm:mx-auto sm:w-full sm:max-w-xl bg-gray-100 rounded-3xl">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm  mt-5">
                    <img
                        alt="Your Company"
                        src="images/CompanyLogo.png"
                        className="mx-auto"
                        width={75}
                        // height={30}
                    />
                    <h2 className="mt-10 text-center text-lg md:text-2xl sm:text-3xl font-bold leading-9 tracking-wider text-gray-900">
                        Step 02 - Enter OTP
                    </h2>
                    <p className="mt-2 font-light text-sm lg:text-base px-2 sm:px-0 text-center">
                    Enter the OTP that received to your email
                    </p>
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
                    <form onSubmit={handleSubmit}>
                        <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                        placeholder="Enter OTP"
                        className="block w-full mb-4 rounded-md border-0 py-2.0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 font-semibold focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:text-sm xl:text-base sm:leading-6 sm:max-w-md"

                        />
                        <button type="submit" className="flex w-full justify-center rounded-md bg-red-400 px-3 py-3.5 text-sm sm:text-base font-bold leading-3 sm:leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4">Verify OTP</button>
                    </form>
                </div>
                {message && (
                <p className="text-center text-xs sm:text-sm xl:text-base text-red-500 font-semibold mt-4">
                    {message}
                </p>
                )}

                {/* Time Remaining */}
                <p className="text-center text-xs sm:text-sm xl:text-base text-gray-600 font-semibold mt-4 mb-4">
                Time remaining: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}
                </p>
            </div>
        </div>
    </div>

    );
}
export default OTPRecovery;