import { useNavigate } from 'react-router-dom';
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

function ForgetPassword() {
    
    const navigate = useNavigate();
    const initialValues = {
      Memberemail: "",
    };
  
    const LoginSchema = Yup.object().shape({
      Memberemail: Yup.string().required("Username is required"),
    });
  
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values) => {
        console.log(values);
        axios
          .post("https://backend.spkstore.lk/password/send-otp", values)
          //.post("http://localhost:8070/password/send-otp", values)
          .then((Response) => {
            console.log(Response.data);
            navigate('/otpRecovery', { state: { email: values.Memberemail } });
          })
          .catch((error) => {
            console.error("In valid login details", error);
            window.alert('Invalid credentials. Please try again.');
          });
      },
    });
    return(
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-14">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl bg-gray-100 rounded-3xl">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm  mt-5">
            <img
              alt="Your Company"
              src="images/CompanyLogo.png"
              className="mx-auto"
              width={75}
              // height={30}
            />
            <h2 className="mt-10 text-center text-lg md:text-2xl sm:text-3xl font-bold leading-9 tracking-wider text-gray-900">
              Step 01 - Reset Password
            </h2>
            <p className="mt-2 font-light text-sm lg:text-base px-2 sm:px-0 text-center">
            Enter the correct email address get the otp to reset the password
            </p>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
            <form onSubmit={formik.handleSubmit}>
              <div className="px-3">
                <input
                  id="Memberemail"
                  name="Memberemail"
                  type="text"
                  autoComplete="username"
                  placeholder="E-mail"
                  value={formik.values.Memberemail}
                  onChange={formik.handleChange("Memberemail")}
                  className="block w-full mb-4 rounded-md border-0 py-2.0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 font-semibold focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:text-sm xl:text-base sm:leading-6 sm:max-w-md"
                />
                {formik.errors.Memberemail ? (
                  <div className="text-red-500 ">
                    {formik.errors.Memberemail}
                  </div>
                ) : null}

                <div className="">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-red-400 px-3 py-3.5 text-sm sm:text-base font-bold leading-3 sm:leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4"
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* div here */}
        </div>
      </div>
    </div> 
    );
}
export default ForgetPassword;