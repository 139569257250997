import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import axios from "axios";
import Pagination from "../Components/Pagination";
import Records from "../Components/Records";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


function SmartPhonePage() {

  const cookieget = Cookies.get("memberToken");

  let username = '';

  if (cookieget) {
    const decoded = jwtDecode(cookieget);
    if (decoded.given_name) {
       username = decoded.given_name
    }else{

      username = decoded.username || '';
    }
  
}

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(9);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleChange = async (e) => {
    const { name, value, checked } = e.target;
    setSelectedOptions(prevState =>
      checked
        ? [...prevState, { field: name, value }]
        : prevState.filter(option => option.field !== name || option.value !== value)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = selectedOptions.length > 0
          ? await axios.post('https://backend.spkstore.lk/product/getProductsFilter', { checkboxes: selectedOptions })
          : await axios.get('https://backend.spkstore.lk/product/getProducts');
        
        const filteredData = response.data.data.filter(product => product.itemType === "SmartWatch");
        setData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [selectedOptions]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);


  let currentRecords;
  let nPages = [];

  if (Array.isArray(data) && data.length > 0) {
    currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  } else {
    currentRecords = []; // or null, depending on your preference
  }

  // Then, you can check if currentRecords is empty and display a message
  if (currentRecords.length === 0) {
    console.log("No records found.");
    // Display a message to the user in your UI
  } else {
    // Proceed with displaying the currentRecords
    nPages = Math.ceil(data.length / recordsPerPage); // Ensure at least one page if data is not empty
  }
  // const nPages = Math.ceil(data.length / recordsPerPage);

  const FilterName = ({filtername,filterfield}) => {
    return (
      <div className="flex items-center align-middle">
        <input
          value= {filtername}
          name={filterfield}
          // defaultChecked=""
          // id="flexCheckDefault"
          // name="languages"
          type="checkbox"
          checked={selectedOptions.some(
            (option) =>
              option.field === filterfield && option.value === filtername
          )}
          onChange={handleChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
        <p className="ml-3" style={{ color: "#757575" }}>
          {filtername}
        </p>
      </div>
    );
  };

  return (
    <div className="flex h-screen flex-col">
      <Navbar data={username} />

      <div className="2xl:flex overflow-hidden relative flex-col justify-center items-start min-h-[420px] max-md:pr-5">
        <img
          loading="lazy"
          src="images/smartwatch.jpg"
          className="object-cover object-[90%_50%] lg:object-cover absolute inset-0 size-full sm:aspect-[1.85]"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9960fc1df6c020e8e19f8eb538773352dab936d80fb9432ce1c852a919c8ee4?"
          className="absolute max-sm:object-cover max-sm:bottom-0 max-sm:left-0 w-10/12 h-1/5 bg-opacity-80 sm:w-7/12 sm:h-full sm:object-none sm:object-right lg:object-cover"
        />
        <p className="absolute left-4 sm:left-6 lg:left-14 xl:left-24 mt-[350px] sm:mt-44 text-3xl md:text-5xl lg:text-6xl lx:text-8xl text-white font-bold tracking-wide">
          SmartWatches
        </p>
      </div>
      <button
        className="md:hidden text-xl sm:text-2xl mb-4 font-semibold bg-red-400 text-white m-4 p-2"
        onClick={toggleSidebar}
      >
        Filter ☰ 
      </button>
      <div className="flex flex-row">
      <div className={`${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed inset-0 md:translate-x-0 md:relative w-64 sm:w-3/5 lg:w-2/5 xl:w-2/6 bg-white text-red-400 transition-transform duration-300 pl-4 border-r border-gray-500 overflow-y-auto`}>
          <div className="flex mt-10 mb-5 justify-between">
          <h1 className="text-2xl font-semibold">Results</h1>
          <a href="" className="hidden sm:block ml-20 sm:ml-28 lg:ml-32 text-sm text-red-400 border mr-2">
            Clear all
            </a>
            <button
              className="md:hidden text-lg pr-2"
              onClick={toggleSidebar}
            >
              <CloseOutlinedIcon></CloseOutlinedIcon>
            </button>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Brands</h3>
            {/* Add brand checkboxes here */}
            <FilterName filtername="ZeBlaze" filterfield="selectedBrand"></FilterName>
            <FilterName filtername="CMF" filterfield="selectedBrand"></FilterName>
            <FilterName filtername="Redmi" filterfield="selectedBrand"></FilterName>
            <FilterName filtername="Haylou" filterfield="selectedBrand"></FilterName>
          </div>

{/* 
          <div className="mt-4 ">
            <h3 className="text-lg font-semibold">Availability</h3>
            <div className="flex items-center align-middle mt-4">
              <input
                value="In stock"
                name="availability"
                type="checkbox"
                checked={selectedOptions.some(option => option.field === 'availability' && option.value === 'In stock')}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <p className="ml-3" style={{ color: '#757575' }}>In Stock</p>
            </div>
            <div className="flex items-center align-middle">
              <input
                value="Pre Order"
                name="availability"
                type="checkbox"
                checked={selectedOptions.some(option => option.field === 'availability' && option.value === 'Pre Order')}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <p className="ml-3" style={{ color: '#757575' }}>Pre Order</p>
            </div>
          </div> */}


           
          <div className="mt-4 mb-72">
            <h3 className="text-lg font-semibold mb-4">Offers</h3>
            <FilterName filtername="Hot Deals" filterfield="Offers"></FilterName>
            <a href="" className="sm:hidden ml-44 text-sm text-red-400 border">
              Clear all
            </a>
          </div>
        </div>

        <div className="container m-5">
          <div className="flex flex-col align-middle">
            <div className="h-[1350px]">
              {nPages > 0 ? (
                <Records data={currentRecords} />
              ) : (
                <p className="text-lg tracking-wider font-thin italic mx-auto justify-center items-center ">No records found.</p>
              )}
            </div>
            {nPages > 0 && (
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SmartPhonePage;
