import React, { useEffect, useState } from "react";
import UserDashboardNav from "../Components/UserDashboardNav";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; 

function PassordReset() {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [error1, setError1] = useState('');
    const [field2, setField2] = useState('');
    const [field3, setField3] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state?.email;
    const handleSubmit = async (event) => {
        event.preventDefault();
    
       if (field2 === field3) {
        console.log(email);
        try {
          const response = await axios.patch(`https://backend.spkstore.lk/member/updatePassword/${email}`, {
            field2,
            field3
          });
          console.log('Response:', response.data);
          window.alert('Your password has been updated successfully!');
          navigate('/login'); // Redirect to reset password page
        } catch (error) {
          console.error('Error submitting data:', error);
        }
      }else{
        setError("new password and confirm password should be match")
        return;  
    
      }
      
      };

    return(
        <div
            className="bg-white p-6 rounded-lg shadow-lg mt-8 mx-4 lg:mx-24"
            style={{ border: "1px solid black", borderRadius: "8px" }}
          >
            {/* Change password label */}
            <h2 className="text-xl font-bold text-black-500 mt-6">
              Change Password
            </h2>

            {/* form starts */}
            <form className="space-y-4 mt-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4">
                {/* New password section */}
                <div className="relative">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      New Password
                    </label>
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      value={field2}
                      onChange={(e) => setField2(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 mt-5"
                  >
                      {showPassword ? (
                    <VisibilityOutlinedIcon></VisibilityOutlinedIcon>
                  ) : (
                    <VisibilityOffOutlinedIcon></VisibilityOffOutlinedIcon>
                  )}
                  </button>
                </div>

                {/* Confirm password section */}
                <div className="relative">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Confirm Password
                    </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      value={field3}
                      onChange={(e) => setField3(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 mt-5"
                  >
                      {showPassword ? (
                    <VisibilityOutlinedIcon></VisibilityOutlinedIcon>
                  ) : (
                    <VisibilityOffOutlinedIcon></VisibilityOffOutlinedIcon>
                  )}
                  </button>
                </div>
              </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}

              <div className="flex flex-col sm:flex-row sm:space-x-4 mt-3 justify-end">
                {/* Cancel button */}
                <button
                  type="button"
                  className="py-2 rounded-md bg-white text-[#F96969] border-2 border-[#F96969] hover:bg-[#F96969] hover:text-white w-[160px]"
                >
                  CANCEL
                </button>

                {/* Save button  */}
                <button
                  type="submit"
                  className="py-2 sm:px-4 bg-red-500 text-white border-2 border-transparent rounded-md hover:bg-white hover:text-red-500 hover:border-red-500 mt-3 sm:mt-0 w-[160px]"

                >
                  SAVE
                </button>
              </div>
            </form>
            {/* form ends */}
          </div>
    )
}
export default PassordReset;