import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
// responsive enable
function HomepageComments() {
  const scrollRef = useRef(null);
  const scrollStep = 2; // Amount to scroll each interval
  const scrollDelay = 20; // Delay between scroll steps in milliseconds

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollAmount = 0;

    const autoScroll = () => {
      if (scrollContainer) {
        scrollAmount += scrollStep;
        if (
          scrollAmount >
          scrollContainer.scrollWidth - scrollContainer.clientWidth
        ) {
          scrollAmount = 0;
        }
        scrollContainer.scrollLeft = scrollAmount;
      }
    };

    const scrollInterval = setInterval(autoScroll, scrollDelay);

    return () => clearInterval(scrollInterval); // Cleanup interval on unmount
  }, [scrollStep, scrollDelay]); // Dependencies

  return (
    <div className="scroll-container flex min-h-full flex-1 flex-col justify-center px-2 sm:px-6">
      <div className="scroll-content sm:px-5 py-5 mt-3">
        <div className="md:car">
          {/*<div className="px-5 mt-20 p-5 bg-green-800">*/}
            <div className="flex flex-col lg:flex-row gap-5 lg:gap-10">
              <div className="flex flex-col lg:w-5/12 xl:w-4/12">
                <div className="flex flex-col mt-1.5 font-semibold text-neutral-500 lg:mt-10">
                  <div className="text-lg md:text-xl">Why shop with</div>
                  <div className="mt-3 text-2xl md:text-4xl text-black">
                    SPK<span className="text-red-400"> STORE</span>
                  </div>
                  <div className="mt-9 text-xs sm:text-sm xl:text-base font-medium leading-4">
                    Established in 2021, SPK Store has quickly emerged as a
                    trusted retailerspecializing in cutting-edge technology
                    products. We pride ourselves on offering adiverse range of
                    items including gimbals, drones, cameras, and various
                    electronicdevices. Our commitment to exceptional customer
                    service and robust warranty supportdistinguishes us as a
                    preferred choice among tech enthusiasts.
                  </div>
                </div>
              </div>
              <div
                className="flex overflow-hidden rounded-xl relative"
                ref={scrollRef}
              >
                <div className="flex align-middle">
                  <div className="px-5 max-w-[942px]">
                    <div className="flex gap-5 max-md:flex-row">
                      <ReviewBox 
                        image_name="images/cre1.jpeg" 
                        detailed_review="Customer service was top-
                                        notch. They promptly answered
                                        all my questions and helped
                                        me choose the right drone for
                                        my needs."
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre2.jpeg" 
                        detailed_review="The facial product I bought from this site is wonderful! My skin feels soft and refreshed. Highly recommend for skincare lovers!"
                        number={4}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre3.jpeg" 
                        detailed_review="The drone I purchased here is outstanding! Easy to fly, and the camera quality is top-notch. Just as described. Highly recommend!"
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre4.jpeg" 
                        detailed_review="The gimbal I bought is excellent! Smooth controls, and it is easy to set up. Makes filming a breeze. Highly recommend!"
                        number={4}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre5.jpeg" 
                        detailed_review="This smartwatch I ordered is fantastic! The battery life is impressive, and it tracks my health metrics accurately. Highly recommend it!"
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre6.jpeg" 
                        detailed_review="The mobile phone accessories I purchased are excellent! High quality and work perfectly with my phone. Totally recommend this!"
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre7.jpeg" 
                        detailed_review="The drone accessories I got from this site are awesome! They fit perfectly and improved my flight experience. Highly recommend them!"
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre7.jpeg" 
                        detailed_review="The storage device I ordered is great! Fast data transfer, and it’s very reliable. Just as described. Highly recommend!"
                        number={4}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre8.jpeg" 
                        detailed_review="The drone I purchased from this site is
                            fantastic! The build quality is excellent,
                            and it performs just as described. Highly
                            recommend!"
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre9.jpeg" 
                        detailed_review="The gimbal I purchased from this site is amazing! The stabilization is flawless, and it makes my 
                          video shoots look professional. Highly recommend!"
                        number={5}>
                      </ReviewBox>
                      <ReviewBox 
                        image_name="images/cre10.jpeg" 
                        detailed_review="The drone I purchased from this site is
                                        fantastic! The build quality is excellent,
                                        and it performs just as described. Highly
                                        recommend!"
                        number={5}>
                      </ReviewBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
}

const ReviewBox = ({image_name,detailed_review,number}) => {
  return(
    <div className="flex flex-col ml-5 min-w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-6 pt-8 pb-20 w-full text-sm sm:text-base leading-5 rounded-xl bg-red-400 bg-opacity-10 text-neutral-500 max-md:px-5 max-md:mt-9">
        <div className="flex justify-center">
          <img
            loading="lazy"
            src={image_name}
            className="max-w-full rounded-full border-1 sm:border-2 border-white border-solid aspect-square w-[100px] h-[100px] sm:w-[150px] sm:h-[150px]"
            />
        </div>
        <div className="mt-9">
          &quot;
          {detailed_review}
          &quot;
        </div>
        <div className="flex mt-4 justify-center">
          {Array.from({ length: number}).map((_, index) => (
            <img key={index} src="images/star.png" alt="star" className="w-3 h-3 sm:w-5 sm:h-5"/>
          ))}
        </div>
      </div>
    </div>
  );
};

ReviewBox.propTypes = {
  image_name: PropTypes.string.isRequired,
  detailed_review: PropTypes.any.isRequired,
  number: PropTypes.number.isRequired,
}

export default HomepageComments;
