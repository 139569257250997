import PropTypes from 'prop-types';

const CategoryBox = ({href_name, image_name, category_name}) => {
    return(
        <div className="w-full sm:w-2/6">
          <div className="bg-gray-100 rounded-md aspect-square flex items-center justify-center overflow-hidden"> 
            <a href={href_name}>
              <img
                alt="Your Company"
                src={image_name}
                className="mx-auto hover:scale-x-110 hover:scale-y-110 hover:transition duration-500 ease-in-out object-cover w-11/12 h-11/12"
              />
            </a>
          </div>
          <p className="mt-2 text-xs font-semibold md:text-sm lg:text-base xl:text-lg text-center">{category_name}</p>
        </div>
    );
};

CategoryBox.propTypes = {
    href_name: PropTypes.string.isRequired,
    image_name: PropTypes.string.isRequired,
    category_name: PropTypes.string.isRequired,
}

export default CategoryBox;